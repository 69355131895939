.App {
  text-align: center;
  background-color: #C3E4ED;
}

.pic {
  width: 23%;
  margin: 1%;
}

.pictureContainer{
  display: flex;
  flex-wrap: wrap;
}

.footer {
  width: 98%;
  padding: 1%;
  background-color: #C3E4ED;
}

.profile{
  padding: 1%;
  width: 48%;
}

.intro {
  display: flex;
  alight-item: center;
}

.pageHolder {
  display: flex;
  justify-content: center;
}

.page{
  min-height: 78vh;
  width: 70%;
  background-color: #C3E4ED;
  flex-direction: col;
  padding: 2%;
}

.navbar{
  background-color: #C3E4ED;
  width: 98%;
  display: flex;
  justify-content: space-around;
  padding: 1%;
}

.navbutton{
  text-decoration: none;
  color: white;
  background-color: #88c7dc;
  border-radius: 5px;
  padding: 1%;
  width:  15%;
}

.navbutton:hover {
  color: #88c7dc;
  background-color: white;
}
